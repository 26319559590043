var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"courseDetails"},[_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"padding-top":"20px"}}),_c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"cont courseBoxC",attrs:{"body-style":{ padding: '15px' }}},[_c('div',{staticClass:"left"},[_c('transition',{attrs:{"name":"el-fade-in-linear"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"transition-box"},[_c('h3',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticStyle:{"color":"#00957E","padding":"10px 0"}},[_vm._v(" "+_vm._s(_vm.data.course.course_name)+" ")]),_c('div',{staticClass:"course-tree-list",staticStyle:{"overflow-y":"auto","overflow-x":"hidden"}},[_c('el-collapse',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.entryLoading),expression:"entryLoading"}],attrs:{"accordion":""},on:{"change":_vm.changeAccordion},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.dataList),function(v,i){return _c('el-collapse-item',{key:i,attrs:{"name":v.id}},[_c('template',{slot:"title"},[_c('span',{staticClass:"tiaomu"},[_vm._v(" "+_vm._s(v.title))])]),_vm._l((v._list),function(j,k){return _c('div',{key:k,staticStyle:{"margin-top":"10px"}},[_c('div',{staticStyle:{"width":"100%","margin-left":"20px","font-size":"12px"},attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"catalogue-details-title",on:{"click":function($event){return _vm.getInfoList(j.id, -1)}}},[_c('div',{staticClass:"tiaomu2"},[_c('span',[_vm._v(" "+_vm._s(j.name))])]),(j.duration && j.video)?_c('div',[_c('img',{staticStyle:{"width":"18px","height":"18px"},attrs:{"src":require('@/assets/images/course/timer.png')}}),_c('span',[_vm._v(_vm._s(j.duration))])]):_vm._e()]),_c('el-row',{style:({
                          display: _vm.showList.id === j.id ? 'flex' : 'none',
                        })},[_c('el-col',{attrs:{"span":12}},[_c('el-radio',{style:({ margin: '5px 30px 5px 0' }),attrs:{"disabled":j.pdf.length === 0,"label":2,"fill":"#00957e"},on:{"change":function($event){return _vm.getInfoList(j.id, j.radio)}},model:{value:(j.radio),callback:function ($$v) {_vm.$set(j, "radio", $$v)},expression:"j.radio"}},[_vm._v("PDF")])],1),_c('el-col',{attrs:{"span":12}},[_c('el-radio',{style:({ margin: '5px 30px 5px 0' }),attrs:{"disabled":j.video.length === 0,"label":1,"fill":"#00957e"},on:{"change":function($event){return _vm.getInfoList(j.id, j.radio)}},model:{value:(j.radio),callback:function ($$v) {_vm.$set(j, "radio", $$v)},expression:"j.radio"}},[_vm._v("视频")])],1)],1),_vm._l((j.task),function(vv,ii){return _c('el-row',{key:ii,style:({
                          display: _vm.showList.id === j.id ? 'flex' : 'none',
                        })},[_c('el-col',{staticStyle:{"margin-top":"4px"},attrs:{"span":12}},[_vm._v(" "+_vm._s(vv.task_name)+" ")]),_c('el-col',{staticStyle:{"margin-top":"4px"},attrs:{"span":12}},[_c('el-button',{staticStyle:{"padding":"5px 8px","margin-right":"5px"},attrs:{"size":"mini","type":"primary","round":"","disabled":!(vv.task_id > 0)},on:{"click":function($event){return _vm.$handleRoute(
                                {
                                  id: vv.task_id,
                                  course_id: _vm.id,
                                  teach_id: _vm.teachId,
                                  type: '作业',
                                  duration:1
                                },
                                '/match/questAnswer',
                                '_blank'
                              )}}},[_vm._v(" 进入作业 ")])],1)],1)})],2)])})],2)}),1)],1)])]),_c('div',{staticClass:"out",attrs:{"title":_vm.show ? '目录收缩' : '目录展开'}},[(_vm.show)?_c('i',{staticClass:"el-icon-caret-left",on:{"click":_vm.joinOut}}):_vm._e(),(!_vm.show)?_c('i',{staticClass:"el-icon-caret-right",on:{"click":_vm.joinOut}}):_vm._e()])],1),_c('el-row',{staticClass:"content-main"},[_c('el-col',{style:(_vm.show ? { 'padding-left': '400px' } : {}),attrs:{"span":_vm.currentList.checked ? 12 : 24}},[(_vm.currentList.pdf.indexOf('http') > -1 && !_vm.noPassPrev)?_c('el-pdf',{ref:"pdf",staticClass:"PDF",style:({ display: _vm.currentList.radio === 2 ? 'block' : 'none' }),attrs:{"name":"el-pdf","src":_vm.currentList.pdf,"page":_vm.currentList.pdfCurrent},on:{"pageLoaded":_vm.pdfPageLoaded,"resize":_vm.CourseResize}}):_vm._e(),(_vm.currentList.video.indexOf('http') > -1 && !_vm.noPassPrev)?_c('VideoPlayer',{ref:"VideoPlayer",staticClass:"sp",style:({ display: _vm.currentList.radio === 1 ? 'block' : 'none' }),attrs:{"reloadUrl":_vm.currentList.video,"autoplay":false},on:{"resize":_vm.CourseResize,"timeupdate":_vm.videoUpdateTime}}):_vm._e(),(
              _vm.currentList.pdf.indexOf('http') === -1 &&
              _vm.currentList.video.indexOf('http') === -1
            )?_c('div',{staticStyle:{"width":"100%","height":"100%","display":"table"}},[_c('div',{staticStyle:{"display":"table-cell","vertical-align":"middle","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.errorMsg || _vm.currentList.errorMsg || "暂无视频和PDF")+" ")])]):_vm._e(),(_vm.noPassPrev)?_c('div',{staticStyle:{"width":"100%","height":"100%","display":"table"}},[_c('div',{staticStyle:{"display":"table-cell","vertical-align":"middle","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.errmsg || "上一章节未通过")+" ")])]):_vm._e()],1),_c('el-col',{staticClass:"cardRight",style:({ display: _vm.currentList.checked ? 'block' : 'none' }),attrs:{"span":12}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"lxt"},[_c('TopicSum',{ref:"TopicSum",attrs:{"empty-text":'暂无作业',"discussShow":false,"status":"preview","deploy":_vm.deploy},on:{"setPitchNums":_vm.setPitchNums,"setAnswers":_vm.setAnswers,"complateAnswer":_vm.complateAnswer}}),_c('div',{staticClass:"btnBox"},[_c('el-button',{staticStyle:{"padding":"4px 10px"},attrs:{"size":"mini","type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 提 交 ")])],1)],1)])],1)],1)],1),_c('SubmitPop',{ref:"SubmitPop",on:{"confirmPop":_vm.confirmPop,"cancelPop":_vm.cancelPop,"closePop":_vm.cancelPop}},[(_vm.pitchNums.length < _vm.topicParam.topicList.length)?_c('div',{staticStyle:{"color":"rgba(233, 75, 50, 1)"}},[_vm._v(" 你还有试题没有做完，确定提交吗？ ")]):_c('div',[_vm._v("确定提交吗？")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
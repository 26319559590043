<!-- 学习页面 -->
<template>
  <div class="courseDetails">
    <div class="content">
      <div style="padding-top: 20px"></div>
      <!-- 内容区域 -->
      <el-card
        class="cont courseBoxC"
        :body-style="{ padding: '15px' }"
        v-loading="loading"
      >
        <div class="left">
          <transition name="el-fade-in-linear">
            <div v-show="show" class="transition-box">
              <h3 style="color: #00957E; padding: 10px 0" v-show="show">
                {{ data.course.course_name }}
              </h3>
              <div
                class="course-tree-list"
                style="overflow-y: auto; overflow-x: hidden"
              >
                <el-collapse
                  v-model="activeName"
                  accordion
                  @change="changeAccordion"
                  v-loading="entryLoading"
                >
                  <el-collapse-item
                    :name="v.id"
                    v-for="(v, i) in dataList"
                    :key="i"
                   
                  >
                    <template slot="title">
                      <!-- <span
                        :style="!v.is_pass ? 'color:#0184bb;' : ''"
                        class="tiaomu"
                        > -->
                      <span class="tiaomu"> {{ v.title }}</span>
                    </template>
                    <div
                      v-for="(j, k) in v._list"
                      :key="k"
                      style="margin-top: 10px"
                    >
                      <div slot="title" style="width: 100%;margin-left: 20px;font-size: 12px;">
                        <div
                          class="catalogue-details-title"
                          @click="getInfoList(j.id, -1)"
                        >
                          <div class="tiaomu2">
                            <!-- <span :style="!j.is_pass ? 'color:#0184bb;' : ''"> -->
                            <!-- <span> {{ j.title }}</span> -->
                            <span> {{ j.name }}</span>
                          </div>
                          <div v-if="j.duration && j.video">
                            <img
                              :src="require('@/assets/images/course/timer.png')"
                              style="width: 18px; height: 18px"
                            />
                            <span>{{ j.duration }}</span>
                          </div>
                        </div>
                        <el-row
                          :style="{
                            display: showList.id === j.id ? 'flex' : 'none',
                          }"
                        >
                          <el-col :span="12">
                            <el-radio
                              v-model="j.radio"
                              :disabled="j.pdf.length === 0"
                              :label="2"
                              fill="#00957e"
                              @change="getInfoList(j.id, j.radio)"
                              :style="{ margin: '5px 30px 5px 0' }"
                              >PDF</el-radio
                            >
                          </el-col>
                          <el-col :span="12">
                            <el-radio
                              :disabled="j.video.length === 0"
                              v-model="j.radio"
                              :label="1"
                              fill="#00957e"
                              @change="getInfoList(j.id, j.radio)"
                              :style="{ margin: '5px 30px 5px 0' }"
                              >视频</el-radio
                            >
                          </el-col>

                          <!-- <el-col :span="8" style="margin-top:4px">
                            <el-button  v-if="j.task_id > 0" size="mini" type="primary" @click="$handleRoute({ id: j.task_id , course_id: id, teach_id: teachId,type: '作业' },'/match/questAnswer','_blank')" style="padding: 5px 8px;margin-right:5px;" round :disabled="!(j.task_id > 0)">
                              习题入口
                            </el-button>
                          </el-col> -->
                        </el-row>

                        <!-- <el-row v-for="vv,ii in j.task" :key="ii" :style="{ display: showList.id === j.id ? 'flex' : 'none'}"> -->
                        <el-row
                          v-for="(vv, ii) in j.task"
                          :key="ii"
                          :style="{
                            display: showList.id === j.id ? 'flex' : 'none',
                          }"
                        >
                          <el-col :span="12" style="margin-top: 4px">
                            {{ vv.task_name }}
                          </el-col>
                          <el-col :span="12" style="margin-top: 4px">
                            <el-button
                              size="mini"
                              type="primary"
                              @click="
                                $handleRoute(
                                  {
                                    id: vv.task_id,
                                    course_id: id,
                                    teach_id: teachId,
                                    type: '作业',
                                    duration:1
                                  },
                                  '/match/questAnswer',
                                  '_blank'
                                )
                              "
                              style="padding: 5px 8px; margin-right: 5px"
                              round
                              :disabled="!(vv.task_id > 0)"
                            >
                              进入作业
                            </el-button>
                          </el-col>

                          <!-- <el-col :span="8" style="margin-top:4px">
                            <el-button  v-if="j.task_id > 0" size="mini" type="primary" @click="$handleRoute({ id: j.task_id , course_id: id, teach_id: teachId,type: '作业' },'/match/questAnswer','_blank')" style="padding: 5px 8px;margin-right:5px;" round :disabled="!(j.task_id > 0)">
                              习题入口
                            </el-button>
                          </el-col> -->
                        </el-row>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </transition>
          <div class="out" :title="show ? '目录收缩' : '目录展开'">
            <i @click="joinOut" class="el-icon-caret-left" v-if="show"></i>
            <i @click="joinOut" class="el-icon-caret-right" v-if="!show"></i>
          </div>
        </div>
        <el-row class="content-main">
          <el-col
            :span="currentList.checked ? 12 : 24"
            :style="show ? { 'padding-left': '400px' } : {}"
          >
            <el-pdf
              name="el-pdf"
              ref="pdf"
              :src="currentList.pdf"
              :page="currentList.pdfCurrent"
              v-if="currentList.pdf.indexOf('http') > -1 && !noPassPrev"
              @pageLoaded="pdfPageLoaded"
              class="PDF"
              @resize="CourseResize"
              :style="{ display: currentList.radio === 2 ? 'block' : 'none' }"
            ></el-pdf>
            <VideoPlayer
              class="sp"
              :style="{ display: currentList.radio === 1 ? 'block' : 'none' }"
              :reloadUrl="currentList.video"
              ref="VideoPlayer"
              :autoplay="false"
              @resize="CourseResize"
              @timeupdate="videoUpdateTime"
              v-if="currentList.video.indexOf('http') > -1 && !noPassPrev"
            ></VideoPlayer>
            <div
              style="width: 100%; height: 100%; display: table"
              v-if="
                currentList.pdf.indexOf('http') === -1 &&
                currentList.video.indexOf('http') === -1
              "
            >
              <div
                style="
                  display: table-cell;
                  vertical-align: middle;
                  text-align: center;
                "
              >
                {{ errorMsg || currentList.errorMsg || "暂无视频和PDF" }}
              </div>
            </div>
            <!-- <div style="width: 100%; height: 100%;display: table;" v-if="currentList.pdf.indexOf('http') === -1 && currentList.video.indexOf('http') === -1">
              <div style="display: table-cell;vertical-align: middle;text-align: center;">{{errorMsg || currentList.errorMsg || '暂无视频和PDF'}}</div>
            </div> -->
            <div
              style="width: 100%; height: 100%; display: table"
              v-if="noPassPrev"
            >
              <div
                style="
                  display: table-cell;
                  vertical-align: middle;
                  text-align: center;
                "
              >
                {{ errmsg || "上一章节未通过" }}
              </div>
            </div>
          </el-col>
          <el-col
            :style="{ display: currentList.checked ? 'block' : 'none' }"
            :span="12"
            class="cardRight"
          >
            <div class="lxt" v-loading="loading">
              <TopicSum
                ref="TopicSum"
                :empty-text="'暂无作业'"
                :discussShow="false"
                status="preview"
                :deploy="deploy"
                @setPitchNums="setPitchNums"
                @setAnswers="setAnswers"
                @complateAnswer="complateAnswer"
              ></TopicSum>
              <div class="btnBox">
                <el-button
                  size="mini"
                  type="primary"
                  @click="submit"
                  style="padding: 4px 10px"
                >
                  提 交
                </el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <SubmitPop
      ref="SubmitPop"
      @confirmPop="confirmPop"
      @cancelPop="cancelPop"
      @closePop="cancelPop"
    >
      <div
        v-if="pitchNums.length < topicParam.topicList.length"
        style="color: rgba(233, 75, 50, 1)"
      >
        你还有试题没有做完，确定提交吗？
      </div>
      <div v-else>确定提交吗？</div>
    </SubmitPop>
  </div>
</template>

<script>
import {
  getListQuest,
  kcAnswer,
  jurisdiction,
  timePasslog,
} from "@/api/ladder.js";

import VideoPlayer from "@/components/VideoPlayer/index.vue"; //视频播放
import TopicSum from "@/components/TopicSum/TopicSum.vue"; //练习题
import SubmitPop from "@/components/SubmitPop/SubmitPop.vue";
import elPdf from "@/components/com/el/elPdf";
import $ from "jquery";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { VideoPlayer, TopicSum, SubmitPop, elPdf },
  data() {
    //这里存放数据
    return {
      loading: false,
      entryLoading: false,
      start_time: new Date().getTime(), //开始时间
      id: "", //课程id
      pitchNums: [],
      questionsNum: 0, //题目总数
      topicParam: {
        topicList: [],
        knowledgeTag: [],
      },
      detailList: {}, //题目数据

      // 题目组件
      deploy: { isAnswer: false },

      radio: null, //视频1 FPD2 暂无3
      checked: false, //选中 显示练习题
      show: true, //是否显示左侧目录
      activeName: "",
      //目录数据
      data: { course: {} },
      dataList: [],
      showList: { id: "" },
      questloading: false,
      errorMsg: null,
      currentList: {
        id: "",
        pdf: "",
        video: "",
        radio: "",
        checked: false,
        videoCurrentTime: "",
        videoDuration: "",
        pdfCurrent: 1,
        pdfTotal: 1,
        questions_list: [],
      },
      task_id: 0,
      teachId: 0,
      boxStyle: {},
      noPassPrev: false, //上个章节未通过展示
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    task_id(id) {
      if (this.currentList.checked && id) {
        this.getDetailList(id);
      }
    },
    "currentList.checked"(v) {
      if (v && this.task_id) {
        this.getDetailList(this.task_id);
      }
    },
    "currentList.radio"(v) {
      if (this.currentList.radio === 2) {
        // this.$refs.VideoPlayer && this.$refs.VideoPlayer.pause();
      } else if (this.currentList.radio === 1) {
        // this.$refs.VideoPlayer && this.$refs.VideoPlayer.play();
      }
    },
    showList: {
      deep: true,
      immediate: true,
      handler(v, o) {
        if (
          (v || {}).id === ((o || {}).id || (v || {}).id) &&
          (v || {}).id > 0
        ) {
          this.currentList.checked = v.checked === true;
          this.currentList.radio = parseInt(v.radio);
          // console.log(this.dataList[v.dataIndex],"this.dataList[v.dataIndex]this.dataList[v.dataIndex]");
          if (this.dataList[v.dataIndex]) {
            this.dataList[v.dataIndex]._list[v.listIndex].checked =
              v.checked === true;
            this.dataList[v.dataIndex]._list[v.listIndex].radio = parseInt(
              v.radio
            );
          }
        }
      },
    },
    show(v) {
      this.CourseResize();
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.CourseResize();
    this.id = this.$route.query.id;
    this.teachId = parseInt(this.$route.query.tid || 0);
    // this.getCourseDetail();
    this.getNochapterDetails();
  },
  //   页面销毁
  beforeDestroy() {
    this.getAdopt();
  },
  //方法集合
  methods: {
    CourseResize() {
      let width = $(this.$el).find(".content:eq(0)").width();
      let height = $(this.$el).find(".content:eq(0)").height();
      console.log(width,"width")
      console.log(height,"height")
      this.boxStyle.width = width;
      this.boxStyle.height = height;
      this.boxStyle.courseWidth = width;
      this.boxStyle.courseHeight = (width / 16) * 9;
      $(this.$el)
        .find(".content:eq(0)")
        .css("height", this.boxStyle.courseHeight + 70 + "px");
      $(this.$el)
        .find(".courseBoxC")
        .css("height", this.boxStyle.courseHeight + 30 + "px");
      $(this.$el)
        .find(".courseBoxC")
        .find(".content-main")
        .css("height", this.boxStyle.courseHeight -240+ "px");
      $(this.$el)
        .find(".courseBoxC")
        .find(".left")
        .css("height", this.boxStyle.courseHeight + "px");
      $(this.$el)
        .find(".courseBoxC")
        .find(".left")
        .find(".course-tree-list")
        .css("height", this.boxStyle.courseHeight - 48 + "px");
    },
    videoUpdateTime({ duration, currentTime }) {
      var time = currentTime - (this.currentList.videoSaveCurrentTime || 0);
      this.currentList.videoCurrentTime = currentTime;
      this.currentList.videoDuration = duration;
      if (currentTime === duration) {
        this.getAdopt();
      } else if (
        !this.currentList.videoSaveCurrentTime ||
        time >= 1 ||
        time < 0
      ) {
        this.currentList.videoSaveCurrentTime = currentTime;
        this.getAdopt();
      }
    },
    pdfPageLoaded(page, total) {
      this.currentList.pdfCurrent = page;
      this.currentList.pdfTotal = total;
      if (page === total) {
        this.getAdopt();
      } else if (this.currentList.savePage !== page) {
        this.currentList.savePage = page;
        this.getAdopt();
      }
    },
    // 点击通过
    getAdopt() {
      var current, total;
      if (this.currentList.radio === 2) {
        current = this.currentList.pdfCurrent || 1;
        total = this.currentList.pdfTotal;
      } else if (this.currentList.radio === 1) {
        current = this.currentList.videoCurrentTime || 0;
        total = this.currentList.videoDuration;
      }
      if ([1, 2].indexOf(this.currentList.radio) >= 0 && total) {
        let data = {
          course_id: this.id,
          list_id: this.currentList.id,
          type: this.currentList.radio, //	类型：1视频 2pdf
          current: current, //当前页数或时间
          total: total, //总页数或总时间
          course_teach_id: this.teachId, // 老师教程Id
        };
        timePasslog(data);
      }
    },

    // 获取不带章节目录
    getNochapterDetails() {
      this.loading = true;
      this.$http
        .post("/xapi/course.course/detail_simplen", {
          id: this.id,
          tid: this.teachId,
          page: 1,
          pageSize: 999,
        })
        .then(({ data }) => {
          let { lists, ...course } = data;
          if (!(course.isStudent || course.isTeach)) {
            this.errorMsg =
              "您不是课程【" + course.course_name + "】的老师或学生！";
            return this.$message.error(this.errorMsg);
          }
          this.data = { course };
          this.getChapterDetail()
          // let v = lists.data[0];
          // this.dataList = lists.data;
          // if (this.$route.query.list_id) {
          //   this.getInfoList(parseInt(this.$route.query.list_id), -1);
          // }
          // if (!this.showList.id) {
          //   if (lists.study_last_id) {
          //     console.log("有lastid");

          //     this.getInfoList(lists.study_last_id, -1);
          //   } else {
          //     console.log("无lastid");
          //     this.getInfoList(v._list[0].id, -1);
          //   }
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取章节列表
    getChapterDetail() {
      this.$http
        .post("/xapi/course.course/getUnitList", {
          course_id:this.id,
          id: this.id,
          page: 1,
          pageSize: 999,
        })
        .then(({ data }) => {
          console.log(data, "章节信息");
          this.lists = data.data;
          console.log(this.lists, "this.liststhis.lists");

          let v = this.lists[0];
          this.dataList = this.lists;
          if (this.$route.query.list_id) {
            this.getInfoList(parseInt(this.$route.query.list_id), -1);
          }
          if (!this.showList.id) {
            if (this.lists.study_last_id) {
              console.log("有lastid");

              this.getInfoList(this.lists.study_last_id, -1);
            } else {
              console.log("无lastid");
              this.getInfoList(v._list[0].id, -1);
            }
          }
        });
    },

    // 获取目录
    getCourseDetail() {
      this.loading = true;
      this.$http
        .post("/xapi/course.course/detail", {
          id: this.id,
          tid: this.teachId,
          page: 1,
          pageSize: 999,
        })
        .then(({ data }) => {
          let { lists, ...course } = data;
          if (!(course.isStudent || course.isTeach)) {
            this.errorMsg =
              "您不是课程【" + course.course_name + "】的老师或学生！";
            return this.$message.error(this.errorMsg);
          }
          this.data = { course, lists };

          let v = lists.data[0];
          this.dataList = lists.data;
          // console.log(this.dataList,"this.dataListthis.dataList");
          // 首次进入判断有无播放权限
          // if(v._list && v._list.length>0){
          //   jurisdiction({ course_id: this.id, list_id:v._list[0].id}).then(res=>{
          //     console.log(res,"首次进入判断权限");
          //  }).catch(err=>{
          //   //  console.log(err,"errerrerr");
          //       if(err.code==500){
          //         this.noPassPrev=true
          //         this.errmsg=err.msg
          //       }
          //  })
          //    }
          if (this.$route.query.list_id) {
            this.getInfoList(parseInt(this.$route.query.list_id), -1);
          }
          if (!this.showList.id) {
            if (lists.study_last_id) {
              console.log("有lastid");

              this.getInfoList(lists.study_last_id, -1);
            } else {
              console.log("无lastid");
              this.getInfoList(v._list[0].id, -1);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 点击目录，获取视频、pdf
    getInfoList(listId, iscurrent, ismsg) {
      console.log(this.dataList, "this.dataList");
      ismsg = ismsg === undefined ? true : ismsg;
      var setCurrent = [true, 1, 2].indexOf(iscurrent) > -1;
      console.log(setCurrent, "setCurrent");
      for (let i = 0; i < this.dataList.length; i++) {
        // console.log(this.dataList[i],"this.dataList[i]this.dataList[i]this.dataList[i]");
        var item1 = this.dataList[i];
        for (let j = 0; j < item1._list.length; j++) {
          var item = item1._list[j];
          if (iscurrent === -1) {
            item.pdf = (item.pdf || "").toString();
            item.video = (item.video || "").toString();
            item.task_id = parseInt(item.task_id || 0);
            item.radio = item.radio || "";
            item.checked = item.checked || false;
            item.videoCurrentTime = item.videoCurrentTime || 0;
            item.videoDuration = item.videoDuration || 0;
            item.pdfCurrent = item.pdfCurrent || 1;
            item.pdfTotal = item.pdfTotal || 1;
            item.questions_list = [];
            item.dataIndex = i;
            item.listIndex = j;
          }
          if (item.id === listId) {
            this.showList = { ...item, $ismsg: ismsg, $iscurrent: iscurrent };
            console.log(this.showList, "this.showListthis.showList");
            this.changeShowData(this.showList);
          } else if (item.id !== this.currentList.id || setCurrent) {
            item.radio = null;
            item.checked = false;
          }
        }
      }
    },
    changeShowData(v) {
      // console.log(v, "vvvvvvvvvv");
      let iscurrent = v.$iscurrent;
      let ismsg = v.$ismsg;
      // console.log(this.dataList[v.dataIndex], "this.dataList[v.dataIndex]");
      let item = {
        ...(((this.dataList[v.dataIndex] || {})._list || [])[v.listIndex] ||
          {}),
      };
      // console.log(item, "itemmmm");
      var setCurrentFunc = (item) => {
        this.loading = true;
        var radio;
        if ([1, 2].indexOf(iscurrent) > -1) {
          // console.log('-----------------------11111');
          radio = parseInt(iscurrent);
        } else {
          // console.log(item.pdf,'---------------------22222');
          // console.log(item.pdf.indexOf("https")>-1,'111111111111');
          // radio =item.pdf && String(item.pdf).indexOf("https") > -1  ? 2 : 1;
          radio = item.pdf ? 2 : 1;
          // console.log(radio,"radioaaaaaa");
          // if(this.radio === null && (this.$route.query.radio || 0)+"" === "2"){
          //   radio = item.pdf.indexOf("http") > -1 ? 2 : 1;
          // }else{
          //   radio = item.video.indexOf("http") > -1 ? 1 : 2;
          // }
        }
        let isChange = this.radio !== radio || this.currentList.id !== item.id;
        this.radio = radio;
        item.radio = this.radio;
        this.start_time = new Date().getTime();
        if (this.currentList.id !== item.id) {
          this.currentList = { ...item };
          console.log(this.currentList, "this.currentListthis.currentList");
        }
        this.showList.radio = item.radio === 1 ? 1 : 2;
        this.showList.checked = item.checked === true;
        if (item.task_id > 0) {
          this.task_id = item.task_id;
        }
        // if(isChange){
        //   this.getAdopt();
        // }
        if (!(iscurrent === true)) {
          setTimeout(() => {
            if (!this.questloading) {
              this.loading = false;
            }
          }, 120);
        }
        if (iscurrent === -1) this.activeName = item.unit_id;
        return item;
      };
      // if ( item.id > 0 && !( item.pdf.indexOf("http") > -1 || item.video.indexOf("http") > -1 ) ) {
      // console.log("请求请求请求");
      this.loading = true;
      // return
      jurisdiction({ course_id: this.id, list_id: item.id ? item.id : v.id })
        .then(({ data, msg }) => {
          if (!data.noShow) {
            msg =
              (msg || "").length > 5
                ? msg
                : "条目【" + item.name + "】没有查看的权限！";
            // return
            if (ismsg === true) {
              this.$message({
                showClose: true,
                message: msg,
                type: "error",
              });
              return;
            }

            if (this.currentList.id) {
              this.showList = { ...this.currentList };
              this.currentList.errorMsg = msg;
            } else {
              this.getInfoList(this.dataList[0]._list[0].id, -1);
            }
          } else {
            // console.log("=================查看成功", data);
            this.noPassPrev = false;
            let item = {
              ...(((this.dataList[v.dataIndex] || {})._list || [])[
                v.listIndex
              ] || {}),
            };
            // item.pdf = data.pdf.toString();
            // item.video = data.video.toString();
            // item.is_pass = data.is_pass === undefined ? item.is_pass : data.is_pass === true;
            // item.task_id = parseInt(
            //     data.task_id || item.task_id
            // );
            this.currentList.id = data.list_id;

            this.currentList.pdf = data.pdf.toString();
            this.currentList.video = data.video.toString();
            this.currentList.is_pass =
              data.is_pass === undefined ? item.is_pass : data.is_pass === true;
            this.currentList.task_id = parseInt(data.task_id || item.task_id);
            console.log(item, "itemmmmmmmmmmmm");

            this.dataList[v.dataIndex]._list[v.listIndex] =
              setCurrentFunc(item);
            this.dataList[v.dataIndex].is_pass =
              this.dataList[v.dataIndex]._list.filter(
                (d) => d.is_pass === false
              ).length === 0;
          }
        })
        .catch((err) => {
          console.log(err, "errerrerr");
          if (err.code == 500) {
            this.noPassPrev = true;
            this.errmsg = err.msg;
          }
        })
        .finally(() => {
          this.loading = false;
        });
      // }else if(item.id > 0){
      //   this.dataList[v.dataIndex]._list[v.listIndex] = setCurrentFunc( item );
      // }
    },
    changeAccordion(e) {
      console.log(e,"eeeeeeeeeeee")
      if(e){
      this.entryLoading=true

      this.$http.post('/xapi/course.course/getCourseListList' ,
        {   
        course_id: this.id,
        unit_id: e
        }).then(({data})=>{
      this.entryLoading=false

            // console.log(data,"条目信息")
            this.dataList.forEach(v=>{
              data.forEach(vv=>{
                // 判断点开的章节与列表章节id是否一致
                if(v.id==vv.unit_id){
                  v._list=data
                }
              })
            })
            // console.log( this.lists," this.lists this.lists~~~~~~~")
      }).finally(()=>{
      });
    }
      // 点击章节
      // var current =
      //     this.dataList.filter((d) => {
      //       return d.id === e;
      //     })[0] || null;
      // if (current) this.getInfoList(current._list[0].id, 0, false);
    },
    // 获取提单
    getDetailList(id) {
      this.loading = true;
      this.questloading = true;
      getListQuest({ id })
        .then(({ data }) => {
          this.loading = true;
          for (let i = 0; i < this.dataList.length; i++) {
            console.log(i, "iiiiiiiiiiiiiiiiii");
            var item1 = this.dataList[i];
            for (let j = 0; j < item1._list.length; j++) {
              var item = item1._list[j];
              if (item.task_id === id) {
                item.questions_info = data.info;
                item.questions_list = data.questions;
                this.dataList[i]._list[j] = item;
                if (this.showList.id === item.id) {
                  this.showList.questions_info = item.questions_info;
                  this.showList.questions_list = item.questions_list;
                }
                if (this.currentList.id === item.id) {
                  this.currentList.questions_info = item.questions_info;
                  this.currentList.questions_list = item.questions_list;
                  this.$refs.TopicSum && (this.$refs.TopicSum.topicList = item);
                }
              }
            }
          }
        })
        .catch(() => {
          this.currentList.checked = false;
          this.showList.checked = false;
        })
        .finally(() => {
          this.loading = false;
          this.questloading = false;
        });
    },
    //右边题目高亮
    setPitchNums(pitchNums) {
      this.pitchNums = pitchNums;
    },
    setAnswers(answers) {
      this.answers = answers;
    },
    submit() {
      this.$refs.SubmitPop.show = true;
      // this.$refs.CountDown.suspend = true;
    },
    complateAnswer({ id }) {
      if (this.currentList.logId === id) {
        this.$message({
          showClose: true,
          message: this.currentList.name + "作业答题完成！",
          type: "success",
        });
      }
    },
    // 确定提交
    confirmPop() {
      this.$refs.SubmitPop.show = false;

      this.loading = true;

      let data = {
        id: this.currentList.task_id,
        course_id: this.id,
        teach_id: this.teachId,
        start_time: this.start_time / 1000,
        last_commit_time: new Date().getTime() / 1000,
        answers: this.answers,
      };
      kcAnswer(data)
        .then((res) => {
          this.currentList.logId = parseInt(res.data.id);
          this.$message({
            showClose: true,
            message: "提交成功！",
            type: "success",
          });
          for (let i = 0; i < this.dataList.length; i++) {
            var item1 = this.dataList[i];
            for (let j = 0; j < item1._list.length; j++) {
              var item = item1._list[j];
              if (item.task_id === data.id) {
                item.checked = false;
                this.dataList[i]._list[j] = item;
                this.currentList = item;
              }
            }
          }
          this.$nextTick(() => {
            this.$refs.TopicSum.startComplateAnswer();
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 取消提交
    cancelPop() {
      this.$refs.SubmitPop.show = false;
    },
    // 点击目录打开关闭
    joinOut() {
      this.show = !this.show;
    },

    // 习题入口
    clickExercises() {
      this.$router.push({
        path: "/match/questAnswer",
        query: {
          id: this.currentList.task_id,
          course_id: this.id,
          teach_id: this.teachId,
          type: "作业",
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.view-top {
  padding-bottom: 2rem;
}
/deep/.content-main {
  .el-col {
    height: 100%;
    > div {
      height: 100%;
    }
  }
}

.btnBox {
  position: absolute;
  right: 1rem;
  bottom: 0;
}

::v-deep {
  .el-collapse-item__wrap {
    width: 100%;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #00957e !important;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #00957e !important;
  }
  .el-radio__input.is-checked {
    .el-radio__inner {
      background: #00957e;
      border-color: #00957e;
    }
  }
}

.xx {
  max-width: 100%;
  min-width: 100%;
  margin: 0 auto;
}
.box-card {
  margin-top: 1rem;
  text-align: center;
  position: relative;
}
.cont {
  position: relative;
  background-color: #ccc;
  overflow: hidden;
  height: 100%;
  .join {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -2px;
    font-size: 16px;
    width: 20px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    // border: 1px solid #ccc;
    background-color: #fff;
    /* border-radius的顺序是 左上 右上 右下 左下 */
    /* 半圆朝左，右边上下角是圆的，左边上下角的宽度设置为0 */
    /* div盒子宽度为高度的一半 */
    /* 右上和右下的宽度与高度取50或者100都没问题 */
    border-radius: 0 100px 100px 0;
  }
  .left {
    position: absolute;
    left: 0;
    top: 15px;
    overflow: hidden;
    z-index: 980;
    display: flex;
    .transition-box {
      width: 400px;
      text-align: center;
      min-height: 100%;
      color: #fff;
      background-color: #fff;
      padding: 0 10px;
      box-sizing: border-box;
      border: 1px solid #ccc;
    }

    .out {
      width: 20px;
      height: 30px;
      line-height: 30px;
      border: 1px solid #ccc;
      background-color: #fff;
      border-left: 0;
      cursor: pointer;
      /* border-radius的顺序是 左上 右上 右下 左下 */
      /* 半圆朝左，右边上下角是圆的，左边上下角的宽度设置为0 */
      /* div盒子宽度为高度的一半 */
      /* 右上和右下的宽度与高度取50或者100都没问题 */
      border-radius: 0 100px 100px 0;
      //position: absolute;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      left: -5px;
      .el-icon-caret-left {
        font-size: 16px;
        color: #000;
      }
    }
  }
}
.catalogue-details-title {
  cursor: pointer;
  //margin-bottom: 10px;
  // align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div {
    img {
      margin-right: 0.5rem;
    }
    margin-right: 5px;
    display: flex;
    text-align: left !important;
    align-items: center;
    .tiaomu2 {
      overflow: hidden;
      white-space: nowrap;

      text-overflow: ellipsis;
    }
  }
  .look {
    margin-left: 2rem;
    padding: 4px 8px;
    height: 16px;
    line-height: 16px;
    color: #ff4829;
    background: #ffe5e5;
    border-radius: 10px;
  }
}
.cardRight {
  position: relative;
  overflow-y: auto;
  padding: 1rem 1rem 0;
  background-color: #fff;
  border: 1px solid #ccc;
  .lxt {
    height: auto;
    .btnBox {
      position: sticky;
      right: 0;
      bottom: 0;
      width: 100%;
      padding: 10px 0 20px 0;
      height: 28px !important;
      background-color: #fff;
      text-align: right;
    }
  }
}
.tiaomu {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
